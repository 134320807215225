<template>
<div>
  <section
  style="background-repeat: repeat; background-position: center center; padding: 0px; background-color: rgb(208,33,24); background-size: auto; background-image: none; box-sizing: border-box;">
  <section>
    <section class="absoluteActive"
      style="box-sizing: border-box; font-size: 16px; overflow: hidden; grid-template-rows: 100%; grid-template-columns: 100%; display: grid; max-width: 100% !important; width: 100%;">
      <section style="grid-row-start: 1; grid-column-start: 1; height: 100%;"><svg viewBox="0 0 375 479"
          xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; pointer-events: none; display: inline-block; width: 100%; vertical-align: top; -webkit-tap-highlight-color: transparent; user-select: none;"></svg>
      </section>
      <section
        style="box-sizing: border-box; transform: scale(1); width: 100%; margin-top: 0%; margin-left: 0%; grid-row-start: 1; grid-column-start: 1; height: max-content; max-width: 100% !important; line-height: 0; display: block; font-size: 16px;">
        <svg viewBox="0 0 375 479" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img data-role="target" src="./img/bg.png"
              data-ratio="1.2777777777777777" data-w="1080"
              style="box-sizing: border-box; vertical-align: top; width: 100%;"></foreignObject>
        </svg>
      </section>
      <section
        style="box-sizing: border-box; transform: scale(1); width: 100%; margin-top: 44.2667%; margin-left: 0%; grid-row-start: 1; grid-column-start: 1; height: max-content; max-width: 100% !important; line-height: 0; display: block; font-size: 16px;">
        <svg viewBox="0 0 375 104" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%">
            <section style="margin: 0px auto; padding: 0.625em 0px; text-align: center; box-sizing: border-box;">
              <section style="margin-bottom: -2.8125em;">
                <section style="letter-spacing: 0.0166667em; font-size: 3.75em; color: rgb(246, 89, 47);"><br>
                </section>
              </section>
              <section style="display: inline-block;">
                <section
                  style="border-radius: 1.25em; padding: 0.1875em 1.25em; margin-top: 0.3125em; background-color: rgb(246, 89, 47); box-sizing: border-box;">
                  <section class="135brush"
                    style="letter-spacing: 0.0714286em; font-size: 0.875em; color: rgb(255, 243, 227);">郑州航空港区管委会
                  </section>
                </section>
              </section>
            </section>
          </foreignObject>
        </svg>
      </section>
      <section
        style="box-sizing: border-box; max-width: 88% !important; transform: scale(1); width: 88%; margin-top: 12.8%; margin-left: 5.86667%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; display: block; font-size: 29.9574px; padding-bottom: 0px;">
        <svg viewBox="0 0 330 188" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%">
            <section style="margin: 0px auto; padding: 0.625em 0px; text-align: center; box-sizing: border-box;">
              <section style="display: inline-block;">
                <section hm_fix="410:186" style="display: flex; justify-content: center; align-items: center;">
                  <p
                    style="vertical-align: inherit; padding: 0.25em 0.375em; letter-spacing: 0.0625em; font-size: 1em; color: rgb(246, 89, 47); line-height: normal;">
                    <br>
                  </p>
                </section>
                <section style="margin-top: 0.3125em; display: flex; justify-content: center; align-items: center;">
                  <section style="border-radius: 6.25em; padding: 0.1875em; background: rgb(255, 175, 79);">
                    <section
                      style="box-sizing: border-box; border-radius: 5em; letter-spacing: 0.05em; height: 2em; width: 2em; font-size: 1.25em; background: rgb(227, 22, 22); color: rgb(248, 216, 195); line-height: 2; transform: rotate(0deg);">
                      <span style="color: rgb(255, 240, 219);"><strong data-brushtype="text"
                          class="135brush">留</strong></span>
                    </section>
                  </section>
                  <section class="box-edit"
                    style="border-radius: 6.25em; padding: 0.1875em; margin-left: -0.5em; background: rgb(255, 175, 79);">
                    <section
                      style="box-sizing: border-box; border-radius: 5em; letter-spacing: 0.05em; height: 2em; width: 2em; font-size: 1.25em; background: rgb(227, 22, 22); color: rgb(248, 216, 195); line-height: 2; transform: rotate(0deg);">
                      <span style="color: rgb(255, 240, 219);"><strong data-brushtype="text"
                          class="135brush">岗</strong></span>
                    </section>
                  </section>
                  <section class="box-edit"
                    style="border-radius: 6.25em; padding: 0.1875em; margin-left: -0.5em; background: rgb(255, 175, 79);">
                    <section
                      style="box-sizing: border-box; border-radius: 5em; letter-spacing: 0.05em; height: 2em; width: 2em; font-size: 1.25em; background: rgb(227, 22, 22); color: rgb(248, 216, 195); line-height: 2; transform: rotate(0deg);">
                      <span style="color: rgb(255, 240, 219);"><strong data-brushtype="text"
                          class="135brush">红</strong></span>
                    </section>
                  </section>
                  <section class="box-edit"
                    style="border-radius: 6.25em; padding: 0.1875em; margin-left: -0.5em; background: rgb(255, 175, 79);">
                    <section
                      style="box-sizing: border-box; border-radius: 5em; letter-spacing: 0.05em; height: 2em; width: 2em; font-size: 1.25em; background: rgb(227, 22, 22); color: rgb(248, 216, 195); line-height: 2; transform: rotate(0deg);">
                      <span style="color: rgb(255, 240, 219);"><strong data-brushtype="text"
                          class="135brush">包</strong></span>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </foreignObject>
        </svg>
      </section>
      <section
        style="box-sizing: border-box; transform: scale(1); width: 100%; margin-top: 19.2%; margin-left: 0%; grid-row-start: 1; grid-column-start: 1; height: max-content; max-width: 100% !important; line-height: 0; display: block; font-size: 16px;">
        <svg viewBox="0 0 375 46" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%">
            <section data-role="paragraph">
              <section align="center" style="text-align: center;"><span
                  style="font-size: 1.8125em; color: rgb(246, 89, 47);"><strong data-brushtype="text"
                    class="135brush">2023春节</strong></span></section>
            </section>
          </foreignObject>
        </svg>
      </section>
      <section
        style="box-sizing: border-box; max-width: 18% !important; transform: scale(1); width: 18%; margin-top: 60.8%; margin-left: 79.2%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; display: block; font-size: 21px;">
        <svg viewBox="0 0 67 69" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img src="./img/3.gif" data-ratio="1.0172413793103448" data-w="116"
              class="assistant" style="box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </foreignObject>
        </svg>
      </section>
      <section
        v-if="num < 5"
        @click="handleJS"
        style="box-sizing: border-box; max-width: 16% !important; transform: scale(1); width: 16%; margin-top: 55.7333%; margin-left: 0%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; display: block; font-size: 16px;">
        <svg viewBox="0 0 60 61" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img src="./img/3.gif" data-ratio="1.0172413793103448" data-w="116"
              class="assistant" style="box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </foreignObject>
        </svg>
      </section>
      <section v-else
        style="box-sizing: border-box; max-width: 16% !important; transform: scale(1); width: 16%; margin-top: 55.7333%; margin-left: 0%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; display: block; font-size: 16px;">
        <a href="https://lgjy-4ggktm0cdc572392-1305883546.tcloudbaseapp.com/jump-mp.html" >
        <svg viewBox="0 0 60 61" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img src="./img/3.gif" data-ratio="1.0172413793103448" data-w="116"
              class="assistant" style="box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </foreignObject>
        </svg>
        </a>
      </section>
      <section
        style="box-sizing: border-box; max-width: 22% !important; transform: scale(1); width: 22%; margin-top: 3.2%; margin-left: 10.9333%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; font-size: 16px;">
        <svg viewBox="0 0 82 83" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img src="./img/4.gif" data-width="100%" data-ratio="1" data-w="300"
              class="assistant" style="box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </foreignObject>
        </svg>
      </section>
      <section
        style="box-sizing: border-box; max-width: 22% !important; transform: scale(1); width: 22%; margin-top: 79.2%; margin-left: 48.2667%; grid-row-start: 1; grid-column-start: 1; height: max-content; line-height: 0; font-size: 16px;">
        <svg viewBox="0 0 82 82" xml:space="default"
          style="box-sizing: border-box; max-width: 100% !important; display: inline-block; width: 100%; vertical-align: top; line-height: 1.6; overflow: visible;">
          <foreignObject height="100%" width="100%"><img src="./img/4.gif" data-width="100%" data-ratio="1" data-w="300"
              class="assistant" style="box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </foreignObject>
        </svg>
      </section>
    </section>
  </section>
  <section>
    <section style="margin: 0px 20px; box-sizing: border-box;">
      <section style="margin: 10px auto; ">
        <section
          style="background-color: rgb(255, 209, 117); display: flex; justify-content: space-between; align-items: center; padding: 5px 10px; border-radius: 25px;position:relative;z-index:3;">
          <section style="flex-shrink: 0;">
            <section class="assistant" style="box-sizing: border-box; width: 25px;"><img src="./img/3.png"
                data-width="100%" data-op="change" draggable="false" data-ratio="1.0526315789473684" data-w="38"
                class="assistant"
                style="max-width: 100% !important; box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
            </section>
          </section>
          <section style="font-size: 16px; color: rgb(208, 33, 24); text-align: center; letter-spacing: 1.5px;">
            <strong class="135brush">活动详情</strong>
          </section>
          <section style="flex-shrink: 0;">
            <section class="assistant" style="box-sizing: border-box; width: 25px;"><img src="./img/3.png"
                data-width="100%" data-op="change" draggable="false" data-ratio="1.0526315789473684" data-w="38"
                class="assistant"
                style="max-width: 100% !important; box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
            </section>
          </section>
        </section>
        <section
          style="display: flex; justify-content: space-between; padding: 0px 35px; margin: -6px 0px; position:relative;z-index:1;">
          <section style="display: flex; align-items: center;">
            <section
              style="box-sizing: border-box; width: 5px; height: 17px; border-radius: 4px; background-color: rgb(255, 210, 115); margin: 0px 2px; overflow: hidden;">
            </section>
            <section
              style="box-sizing: border-box; width: 5px; height: 17px; border-radius: 4px; background-color: rgb(255, 210, 115); margin: 0px 2px; overflow: hidden;">
            </section>
          </section>
          <section style="display: flex; align-items: center;">
            <section
              style="box-sizing: border-box; width: 5px; height: 17px; border-radius: 4px; background-color: rgb(255, 210, 115); margin: 0px 2px; overflow: hidden;">
            </section>
            <section
              style="box-sizing: border-box; width: 5px; height: 17px; border-radius: 4px; background-color: rgb(255, 210, 115); margin: 0px 2px; overflow: hidden;">
            </section>
          </section>
        </section>
        <section
          style="padding: 15px 20px; background-color: rgb(208, 33, 24); border-style: solid; border-width: 2px; border-color: rgb(255, 209, 117); border-radius: 15px; position:relative;z-index:2; ">
          <section
            style="text-align: justify; line-height: 1.75em; letter-spacing: 1.5px; font-size: 14px; color: rgb(255, 209, 117); background: transparent;">
            <section>
              <p style="text-indent:2em;">
                为鼓励员工留郑过年，支持富士康等重点企业稳岗稳产，春节期间“不打烊”，郑州航空港区管委会定于2023年1月21日至27日，向坚守在重点企业和重点项目工作岗位的20万留守员工发放2000万元“留岗红包”，祝各位留岗员工新年快乐、皆得所愿！
              </p>
                <p style="text-indent:2em;">一、发放对象、标准及方式</p>
                <p style="text-indent:2em;">发放对象：春节期间坚守在郑州航空港区重点企业和重点项目工作岗位的留岗员工。</p>
                <p style="text-indent:2em;">发放标准：100元/人，每人限申领1次。</p>
                <p style="text-indent:2em;">发放方式:在“郑好办”APP或微信“零工就业”小程序（郑州航空港区公共就业一站式服务平台）上领取电子红包。</p>
                <p style="text-indent:2em;">二、发放时间</p>
                <p style="text-indent:2em;">2023年1月21日至1月27日，共7天。</p>
                <p style="text-indent:2em;">三、申领程序</p>
                <p style="text-indent:2em;">第一步：符合条件的留岗员工通过“郑好办”APP首页点击《郑州航空港区留岗红包》活动图片或点击“零工就业”小程序（二维码附后），跳转到《郑州航空港区留岗红包》详情页面；</p>
                <p style="text-indent:2em;">第二步：留岗员工在《郑州航空港区留岗红包》详情页面点击“领取留岗红包”按钮；</p>
                <p style="text-indent:2em;">第三步：留岗员工在“留岗红包提现”页面上进行实名认证，输入本人支付宝账户和收款人户名，点击确认;</p>
                <p style="text-indent:2em;">第四步：留岗员工在个人支付宝账户上领取红包。</p>
                <p style="text-indent:2em;">如有问题请咨询在线客服。</p>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section>
    <section style="margin: 0px 20px; box-sizing: border-box;">
      <section
        style="margin: 10px auto; background-color: rgb(208, 33, 24); padding: 5px 7px; border-style: solid; border-width: 2px; border-color: rgb(255, 209, 117); border-radius: 7px; box-sizing: border-box;">
        <section class="assistant" style="box-sizing: border-box; width: 20px;"><img src="./img/4.png" data-width="100%"
            draggable="false" data-ratio="1" data-w="38" class="assistant"
            style="max-width: 100% !important; box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
        </section>
        <section style="display: flex; justify-content: space-between; align-items: center; margin: 3px 0px;">
          <section style="max-width: 40% !important; box-sizing: border-box; width: 40%;">
            <section class="assistant" style="box-sizing: border-box; width: 95px; margin: 0px auto;"><img
                src="./img/code.jpg" data-width="100%" draggable="false" data-ratio="1" data-w="258" class="assistant"
                style="max-width: 100% !important; box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
            </section>
          </section>

          <section
            style="max-width: 60% !important; box-sizing: border-box; width: 60%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <template v-if="isNewYear">
              <a href="https://lgjy-4ggktm0cdc572392-1305883546.tcloudbaseapp.com/jump-mp.html">
                <section
                  style="display: flex;algin-item:center; justify-content: center;background-color: rgb(255, 209, 117); padding: 12px 16px 12px; border-radius: 15px; box-sizing: border-box; ">
                  <section
                    style="font-size: 14px;display: flex;algin-item:center; color: rgb(208, 33, 24); text-align: center;">
                    <strong style="font-size:16px;">领取留岗红包</strong> 
                  </section>
                </section>
              </a>
            </template>
            <template v-else>
              <section
                @click="showToast"
                style="display: flex;algin-item:center; justify-content: center;background-color: rgb(255, 209, 117); padding: 14px 16px 14px; border-radius: 15px; box-sizing: border-box; ">
                <section
                  style="font-size: 14px;display: flex;align-items:center; color: rgb(208, 33, 24); text-align: center;">
                  <strong style="font-size:16px;">领取留岗红包</strong> 
                </section>
              </section>
            </template>
          </section>
        </section>
        <section style="display: flex; justify-content: flex-end;">

          <section class="assistant" style="box-sizing: border-box; width: 25px;"><img src="./img/4.png"
              class="assistant"
              style="max-width: 100% !important; box-sizing: border-box; vertical-align: inherit; width: 100%; display: block;">
          </section>
        </section>
      </section>
    </section>
    <section>
      <section
        style="text-align: center; line-height: 30.75px; font-size: 14px; letter-spacing: 1.5px; color: rgb(255, 209, 117);">
        <p style="vertical-align: inherit;">主办 | 郑州航空港区管委会</p>
      </section>
    </section>
  </section>
  <section>
    <p style="vertical-align: inherit;"><br></p>
  </section>
</section>
</div>
   
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      isNewYear: false,
      format: "YYYY-MM-DD HH:mm:ss",
      startDate: dayjs('2023-01-21 00:00:00').unix(),
      num:0
    };
  },
  mounted() {
    setInterval(() => {
      this.getNowTime();
    }, 300);
    Tracker.expo("enter_lghb_home_page", {
      bizType: "zhb_qx_lghb_2022",
      ext: {
        title: "航空港区留岗红包",
        qx: "航空港区",
      },
    });
  },
  methods: {
    getNowTime() {
      let curDate = dayjs().unix();
      if (curDate > this.startDate) {
        this.isNewYear = true;
      }
    },
    showToast(){
      this.$toast({
        type:"fail",
        icon:"warning-o",
        message:"留岗红包发放时间\n1月21日至1月27日",
        wordBreak:'normal',
        className:'toast-hongbao'
      });
    },
    handleJS(){
      this.num++;
    }
  },
};
</script>
<style>
.toast-hongbao {
  background-color: #fff!important;
  box-shadow: 0px 3px 10px 3px rgb(84 0 0 / 50%)!important;
  color: rgb(208,33,24)!important;
  width: 130px!important;
}
</style>
